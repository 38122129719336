import React, { PropsWithChildren } from "react"
import classnames from "classnames"

export function Title({ children }: PropsWithChildren<{}>) {
  return <h1 className="text-2xl sm:text-4xl">{children}</h1>
}

export function Subtitle({ children }: PropsWithChildren<{}>) {
  return (
    <p className="text-lg sm:text-xl font-semibold uppercase text-gray-600">
      {children}
    </p>
  )
}

type HeadingProps = {
  className?: string
}

export function Heading2({
  children,
  className,
}: PropsWithChildren<HeadingProps>) {
  return (
    <h2
      className={classnames(
        "text-xl sm:text-2xl font-semibold mb-4",
        className
      )}
    >
      {children}
    </h2>
  )
}

export function Heading3({
  children,
  className,
}: PropsWithChildren<HeadingProps>) {
  return (
    <h3
      className={classnames(
        "text-base sm:text-lg font-semibold mb-2",
        className
      )}
    >
      {children}
    </h3>
  )
}

type ParagraphProps = {
  className?: string
  isLast?: boolean
}

export function Paragraph({
  children,
  className,
  isLast,
}: PropsWithChildren<ParagraphProps>) {
  return (
    <p className={classnames({ "mb-4": !isLast }, className)}>{children}</p>
  )
}
