import React from "react"
import classnames from "classnames"

type BarProps = {
  length: number
  size: number
}

export default function Bar({ length, size }: BarProps) {
  return (
    <div className="flex h-1">
      {Array.from({ length }, (_, i) => (
        <span
          className={classnames("flex-auto", {
            "bg-purple-400 shadow-md": size >= i + 1,
          })}
          key={i}
        ></span>
      ))}
    </div>
  )
}
