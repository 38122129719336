import React, { PropsWithChildren, useState } from "react"
import classnames from "classnames"
import Experience from "./experience"
import Projects from "./projects"

type TabName = "experience" | "projects"

type TabRecord = {
  name: TabName
  label: string
}

type SetSelectedTab = (tab: TabName) => void

const tabs: TabRecord[] = [
  {
    name: "experience",
    label: "Experience",
  },
  {
    name: "projects",
    label: "Projects",
  },
]

export default function ExperienceOrProjects() {
  const [selectedTab, setSelectedTab] = useState<TabName>("experience")

  return (
    <div className="">
      <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      {selectedTab === "experience" ? <Experience /> : <Projects />}
    </div>
  )
}

type TabsProps = { selectedTab: TabName; setSelectedTab: SetSelectedTab }

function Tabs({ selectedTab, setSelectedTab }: TabsProps) {
  return (
    <ul className="mb-4">
      {tabs.map(({ name, label }) => (
        <Tab
          key={name}
          isSelected={name === selectedTab}
          onClick={() => setSelectedTab(name)}
        >
          {label}
        </Tab>
      ))}
    </ul>
  )
}

type TabProps = {
  isSelected: boolean
  onClick: () => void
}

function Tab({ isSelected, onClick, children }: PropsWithChildren<TabProps>) {
  return (
    <li
      className={classnames(
        "font-semibold inline-block py-2 px-4 uppercase rounded-md dark:text-white cursor-pointer mr-2",
        { "bg-gray-400 dark:bg-gray-800": isSelected }
      )}
      onClick={onClick}
    >
      {children}
    </li>
  )
}
