import React, { PropsWithChildren } from "react"
import classnames from "classnames"

type ExternalLinkProps = {
  url: string
  className?: string
}

export default function ExternalLink({
  url,
  children,
  className,
}: PropsWithChildren<ExternalLinkProps>) {
  return (
    <a
      className={classnames("font-semibold", className)}
      href={url}
      rel="nofollow noopener"
      target="_blank"
    >
      {children}
    </a>
  )
}
