import React from "react"
import ExternalLink from "./external-link"

export default function PoweredBy() {
  return (
    <footer className="mt-8 p-8 sm:p-0 text-center">
      Powered by{" "}
      <ExternalLink className="text-white underline" url="https://gatsbyjs.org">
        Gatsby
      </ExternalLink>
    </footer>
  )
}
