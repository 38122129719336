import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import Profile from "../components/profile"
import Education from "../components/education"
import ExperienceOrProjects from "../components/experience-and-projects"
import Links from "../components/links"
import Skills from "../components/skills"
import Languages from "../components/languages"
import PoweredBy from "../components/powered-by"

export default function Home() {
  return (
    <Layout>
      <div className="flex flex-wrap">
        <div className="w-full sm:w-2/3">
          <main className="p-8 lg:p-12">
            <Header />
            <Profile />
            <ExperienceOrProjects />
            <Education />
          </main>
        </div>
        <div className="w-full sm:w-1/3 min-h-screen bg-purple-800 text-white">
          <aside className="flex flex-col p-8 lg:p-12 h-full">
            <div className="flex-grow">
              <Links />
              <Skills />
              <Languages />
            </div>
            <PoweredBy />
          </aside>
        </div>
      </div>
    </Layout>
  )
}
