import React, { PropsWithChildren } from "react"
import classnames from "classnames"

type SectionProps = {
  isLast?: boolean
}

export default function Section({
  children,
  isLast,
}: PropsWithChildren<SectionProps>) {
  return (
    <section className={classnames({ "mb-12": !isLast })}>{children}</section>
  )
}
