import React from "react"
import {
  FaLinkedin,
  FaGithubSquare,
  FaTwitterSquare,
  FaCaretSquareRight,
  FaPollH,
} from "react-icons/fa"
import classnames from "classnames"
import Section from "./section"
import { Heading3 } from "./typography"
import ExternalLink from "./external-link"
import { LinkRecord } from "../types"

const data: LinkRecord[] = [
  {
    label: "LinkedIn",
    url: "https://www.linkedin.com/in/crisu83",
    icon: <FaLinkedin />,
  },
  {
    label: "GitHub",
    url: "https://github.com/crisu83",
    icon: <FaGithubSquare />,
  },
  {
    label: "Twitter",
    url: "https://twitter.com/crisu83",
    icon: <FaTwitterSquare />,
  },
  {
    label: "Talks",
    url: "https://youtube.com/playlist?list=PLN1bGC4M_iPxAxT1xVgkU6dZSHxAY9z_I",
    icon: <FaCaretSquareRight />,
  },
  {
    label: "Slides",
    url: "https://slides.com/christofferniska",
    icon: <FaPollH />,
  },
]

export default function Links() {
  return (
    <Section>
      <Heading3 className="font-mono mb-4">Links</Heading3>
      <ul>
        {data.map((record, i) => (
          <li className={classnames({ "mb-4": i !== data.length - 1 })} key={i}>
            <ExternalLink className="flex items-center" url={record.url}>
              <span className="mr-2">{record.icon}</span>{" "}
              <span className="font-mono text-sm leading-none">
                {record.label}
              </span>
            </ExternalLink>
          </li>
        ))}
      </ul>
    </Section>
  )
}
