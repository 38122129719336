import React from "react"
import format from "date-fns/format"
import differenceInYears from "date-fns/differenceInYears"
import differenceInMonths from "date-fns/differenceInMonths"

type DurationProps = {
  fromDate: Date
  toDate?: Date
  dateFormat?: string
  showDuration?: boolean
}

export default function Duration({
  fromDate,
  toDate,
  dateFormat = "MMMM yyyy",
  showDuration = false,
}: DurationProps) {
  const fromText = format(fromDate, dateFormat)
  const toText = toDate ? format(toDate, dateFormat) : "Present"
  const diffText = formatDuration(fromDate, toDate || new Date())

  return (
    <p className="text-sm sm:text-base font-semibold text-gray-600">
      <span className="uppercase">
        {fromText} &ndash; {toText}
      </span>{" "}
      {showDuration && ` · ${diffText}`}
    </p>
  )
}

function formatDuration(fromDate: Date, toDate: Date): string {
  const diffInYears = differenceInYears(toDate, fromDate)
  const diffInMonths =
    differenceInMonths(toDate, fromDate) + 1 - diffInYears * 12
  const result: string[] = []
  if (diffInYears > 0) {
    result.push(`${diffInYears} ${diffInYears > 1 ? "yrs" : "yr"}`)
  }
  if (diffInMonths > 0) {
    result.push(`${diffInMonths} ${diffInMonths > 1 ? "mos" : "mo"}`)
  }
  return result.join(" ")
}
