import React from "react"
import classnames from "classnames"
import Section from "./section"
import { Heading2, Heading3 } from "./typography"
import Duration from "./duration"
import { EducationRecord } from "../types"

const data: EducationRecord[] = [
  {
    degree: "Bachelor in Engineering",
    school: "Arcada University of Applied Sciences",
    from: new Date("2004-09"),
    description: `The main focus of my studies has been programming. During my studies, I have written code in many different programming languages. My studies have been on hold for a long time due to work.`,
  },
]

export default function Education() {
  return (
    <Section isLast>
      <Heading2>Education</Heading2>
      <ul>
        {data.map((record, i) => (
          <li className={classnames({ "mb-4": i !== data.length - 1 })} key={i}>
            <Heading3>
              {record.degree}, {record.school}
            </Heading3>
            <Duration fromDate={record.from} toDate={record.to} />
            {record.description && <p className="mt-2">{record.description}</p>}
          </li>
        ))}
      </ul>
    </Section>
  )
}
