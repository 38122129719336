import React from "react"
import classnames from "classnames"
import Section from "./section"
import { Heading3 } from "./typography"
import Bar from "./bar"
import { LanguageRecord } from "../types"

const data: LanguageRecord[] = [
  { name: "Finnish", level: 5 },
  { name: "Swedish", level: 5 },
  { name: "English", level: 4 },
  { name: "Spanish", level: 1 },
]

export default function Languages() {
  return (
    <Section isLast>
      <Heading3 className="font-mono mb-4">Languages</Heading3>
      <ul>
        {data.map((record, i) => (
          <li className={classnames({ "mb-4": i !== data.length - 1 })} key={i}>
            <p className="mb-1 text-xs lg:text-sm font-mono">{record.name}</p>
            <Bar length={5} size={record.level} />
          </li>
        ))}
      </ul>
    </Section>
  )
}
