import React from "react"
import classnames from "classnames"
import Duration from "./duration"
import Section from "./section"
import { Heading2, Heading3 } from "./typography"
import { ProjectRecord } from "../types"

const data: ProjectRecord[] = [
  {
    title: "Tesseract Platform",
    client: "Tesseract Group",
    domain: "Finance",
    from: new Date("2021-06"),
    to: new Date("2023-02"),
    description: `Built a highly scalable platform that allowed our business to offer lending services to our partners worldwide. Working mainly on our partner-facing Earn API, our background jobs for our Earn product, and our user interface for our operators. I set the base for our code style and configured both our test suites throughout our mono repository. I also configured the CI/CD pipeline to enforce the code style and run the test suites.`,
    skills: [
      "TypeScript",
      "Node.js",
      "REST",
      "RabbitMQ",
      "Postgres",
      "Auth0",
      "Azure",
      "Docker",
      "Kubernetes",
      "Domain-Driven Design",
      "Test-Driven Development",
      "Extreme Programming",
      "Scrum",
      "Git",
    ],
  },
  {
    title: "Sprint AI Android App",
    client: "Sprint AI",
    domain: "Sport",
    from: new Date("2021-04"),
    to: new Date("2022-03"),
    description: `Single-handedly built an Android application for professional athletes using the latest Android design patterns. I wrote custom code to manage state using a unidirectional dataflow, to persist local data using a data store, and to perform input validation.`,
    skills: [
      "Kotlin",
      "Android",
      "Jetpack Compose",
      "Material UI",
      "Retrofit",
      "Moshi",
      "Coil",
      "REST",
      "OAuth2",
      "Kanban",
      "Git",
    ],
  },
  {
    title: "Sightful Web App",
    client: "Sightful",
    domain: "Business Strategy",
    from: new Date("2020-12"),
    to: new Date("2021-05"),
    description: `As the only developer, I took over our product from the previous developer and enabled us to go from prototype to production by rethinking and redesigning both the application and its underlying infrastructure. I introduced NextJS and a bunch of services which allowed us to go into production as soon as possible.`,
    skills: [
      "TypeScript",
      "Node.js",
      "React",
      "Next.js",
      "Apollo",
      "GraphQL",
      "Dgraph",
      "Auth0",
      "Kanban",
      "Git",
    ],
  },
  // {
  //   title: "Back Office Web App",
  //   client: "Compile",
  //   domain: "Software Consulting",
  //   from: new Date("2019-09"),
  //   to: new Date("2020-11"),
  //   description: `Led the development of our internal competence management tool.`,
  //   skills: [
  //     "TypeScript",
  //     "React",
  //     "Material UI",
  //     "Serverless",
  //     "Node.js",
  //     "AWS",
  //     "DynamoDB",
  //     "Git",
  //   ],
  // },
  {
    title: "Back Office Web App",
    client: "NDA",
    domain: "Retail",
    from: new Date("2019-01"),
    to: new Date("2019-08"),
    description: `Joined the project team late to save the project because it was not going as planned, and the launch was closing in. I partially redesigned and refactored the architecture of the application to make sure that it was ready for launch.`,
    skills: [
      "TypeScript",
      "React",
      "Material UI",
      "Apollo",
      "Node.js",
      "GraphQL",
      "Kanban",
      "Git",
    ],
  },
  {
    title: "Publishing Platform",
    client: "A-Lehdet",
    domain: "Publishing",
    from: new Date("2017-02"),
    to: new Date("2018-12"),
    description: `Led the development of a new publishing platform that enabled our client to shut down more than different legacy systems. I was also responsible for keeping all stakeholders across the organization satisfied.`,
    skills: [
      "Flow",
      "Node.js",
      "React",
      "Apollo",
      "GraphQL",
      "Laravel",
      "Contentful",
      "Elastic",
      "Git",
      "Scrum",
      "Domain-Driven Design",
      "Test-Driven Development",
      "Extreme Programming",
    ],
  },
  {
    title: "Bolt.Works Mobile App",
    client: "Bolt.Works",
    domain: "Construction",
    from: new Date("2016-10"),
    to: new Date("2017-01"),
    description: `Led the development of the initial version of a mobile application for hiring constructor workers. I worked on both the mobile application and the backend that we built on serverless architecture.`,
    skills: [
      "Flow",
      "Node.js",
      "React Native",
      "Serverless",
      "REST",
      "Git",
      "Kanban",
      "Domain-Driven Design",
      "Test-Driven Development",
      "Extreme Programming",
    ],
  },
]

export default function Projects() {
  return (
    <Section>
      <Heading2>
        Projects
        <small className="text-sm sm:text-base font-semibold text-gray-600 uppercase ml-2">
          Most recent
        </small>
      </Heading2>
      <ul>
        {data.map((record, i) => (
          <li className={classnames({ "mb-4": i !== data.length - 1 })} key={i}>
            <Heading3>
              {record.title}, {record.client} — {record.domain}
            </Heading3>
            <Duration fromDate={record.from} toDate={record.to} showDuration />
            {record.description && <p className="mt-2">{record.description}</p>}
            {record.skills && (
              <ul className="mt-4">
                {record.skills
                  .sort((a, b) => a.localeCompare(b))
                  .map((skill, j) => (
                    <li
                      className={classnames(
                        "text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white bg-purple-800",
                        { "mr-1 mb-1": j !== record.skills!.length - 1 }
                      )}
                    >
                      {skill}
                    </li>
                  ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </Section>
  )
}
