import React from "react"
import classnames from "classnames"
import Section from "./section"
import { Heading3 } from "./typography"
import Bar from "./bar"
import { SkillRecord } from "../types"

const data: SkillRecord[] = [
  { name: "TypeScript", level: 9, experienceInMonths: 24 },
  { name: "JavaScript", level: 10, experienceInMonths: 120 },
  { name: "React", level: 9, experienceInMonths: 46 },
  // { name: "Serverless", level: 7, experienceInMonths: 11 },
  { name: "Node.js", level: 8, experienceInMonths: 36 },
  { name: "Kotlin", level: 8, experienceInMonths: 12 },
  { name: "Jetpack Compose", level: 8, experienceInMonths: 12 },
  { name: "HTML & CSS", level: 9},
  { name: "GraphQL", level: 9, experienceInMonths: 29 },
  { name: "Git", level: 8 },
  { name: "Full-stack Development", level: 9, experienceInMonths: 86 },
  { name: "Functional Programming", level: 6 },
  { name: "Software Architecture", level: 8, experienceInMonths: 86 },
  { name: "Agile Methodologies", level: 9, experienceInMonths: 134 },
  // { name: "Open-source", level: 8 },
  // { name: "DevOps", level: 5 },
  { name: "Leadership", level: 8 },
  { name: "Teamwork", level: 10 },
  { name: "Communication", level: 9 },
  { name: "Recruitment", level: 8 },
]

export default function Skills() {
  return (
    <Section>
      <Heading3 className="font-mono mb-4">Skills</Heading3>
      <ul>
        {data.map((record, i) => (
          <li className={classnames({ "mb-4": i !== data.length - 1 })} key={i}>
            <p className="mb-1 text-xs lg:text-sm font-mono">{record.name}</p>
            <Bar length={10} size={record.level} />
          </li>
        ))}
      </ul>
    </Section>
  )
}
