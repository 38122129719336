import React from "react"
import Section from "./section"
import { Heading2, Paragraph } from "./typography"

export default function Profile() {
  return (
    <Section>
      <Heading2>Hello there 👋🏻</Heading2>
      <Paragraph>
        I am a seasoned technology professional with 15 years of hands-on
        expertise in software development. My greatest passion lies in writing
        code and crafting user-friendly software using modern technologies.
      </Paragraph>
      <Paragraph>
        I primarily work with TypeScript, Kotlin, and Rust; however, I
        continuously strive to learn new programming languages to expand my
        knowledge. I am a proponent of best practices such as Clean Code,
        Domain-Driven Design, and Extreme Programming.
      </Paragraph>
      <Paragraph isLast>
        When not programming, I enjoy engaging in sports activities, unwinding
        with Netflix, and spending quality time with my family. While I prefer
        water as my go-to drink, I enjoy coffee in the morning and would not
        turn down a glass of good wine.
      </Paragraph>
    </Section>
  )
}
