import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Title, Subtitle } from "./typography"

export default function Header() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "avatar.png" }) {
        childImageSharp {
          fixed(width: 128, height: 128) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <header className="flex flex-wrap mb-12 mt-8 lg:mt-0">
      <div className="flex justify-center mr-0 lg:mr-12 mb-4 lg:mb-0 w-full lg:w-32">
        <Img
          className="rounded-full w-32 h-32"
          fixed={data.file.childImageSharp.fixed}
          alt="Avatar"
        />
      </div>
      <div className="flex flex-col flex-grow justify-center text-center lg:text-left">
        <Title>Christoffer Niska</Title>
        <Subtitle>Tech Wizard</Subtitle>
      </div>
    </header>
  )
}
