import React from "react"
import classnames from "classnames"
import Duration from "./duration"
import Section from "./section"
import { Heading2, Heading3 } from "./typography"
import { ExperienceRecord } from "../types"

const data: ExperienceRecord[] = [
  {
    title: "Tech Lead",
    company: "Voyantic",
    from: new Date("2023-04"),
    description: `As the head of our full-stack development team, I spearhead efforts to recruit top-tier talent to enhance our team's capabilities. Additionally, I implemented best practices and standardized workflows to improve our team's overall efficiency and productivity.`,
    skills: [
      "TypeScript",
      "Node.js",
      "Golang",
      "REST",
      "MQTT",
      "MongoDB",
      "DynamoDB",
      "IoT",
      "AWS",
      "Docker",
      "Kubernetes",
      "Kanban",
      "Git",
    ],
  },
  {
    title: "Solutions Architect",
    company: "Tesseract Investment",
    from: new Date("2021-06"),
    to: new Date("2023-03"),
    description: `Enabled us to offer our lending services to partners all around the world by redesigning and developing our partner-facing product using modern technologies, while ensuring scalability, and maintainability.`,
    skills: [
      "TypeScript",
      "Node.js",
      "REST",
      "RabbitMQ",
      "Postgres",
      "Auth0",
      "Azure",
      "Docker",
      "Kubernetes",
      "Domain-Driven Design",
      "Test-Driven Development",
      "Extreme Programming",
      "Scrum",
      "Git",
    ],
  },
  {
    title: "Android Developer",
    company: "Sprint AI",
    from: new Date("2021-04"),
    to: new Date("2022-03"),
    description: `Published an application for professional athletes using Android devices that allowed them to enjoy our product by developing an Android application using Kotlin and Jetpack Compose, while following industry best practices.`,
    skills: [
      "Kotlin",
      "Android",
      "Jetpack Compose",
      "Material UI",
      "Retrofit",
      "Moshi",
      "Coil",
      "REST",
      "OAuth2",
      "Kanban",
      "Git",
    ],
  },
  {
    title: "Tech Lead",
    company: "Sightful",
    from: new Date("2020-12"),
    to: new Date("2021-05"),
    description: `Launched a service for businesses that enabled them to make better strategic decisions by redesigning and taking our product from prototype to production, while ensuring cost-efficiency and maintainability.`,
    skills: [
      "TypeScript",
      "Node.js",
      "React",
      "Next.js",
      "Apollo",
      "GraphQL",
      "Dgraph",
      "Auth0",
      "Kanban",
      "Git",
    ],
  },
  // {
  //   title: "Technical Advisor",
  //   company: "Spotted.io",
  //   from: new Date("2020-06"),
  //   description: `Adviced a startup that built a service for recruiting technology professionals.`,
  //   skills: [
  //     "TypeScript",
  //     "Node.js",
  //     "React",
  //     "Next.js",
  //     "Apollo",
  //     "GraphQL",
  //     "FaunaDB",
  //     "Git",
  //   ],
  // },
  {
    title: "Technology Evangelist",
    company: "Compile",
    from: new Date("2019-09"),
    to: new Date("2020-11"),
    description: `Helped our consultants to improve their technical skills by talking to people, identifying needs, and creating material for learning new technologies, while ensuring the continuation and enhancements of services.`,
    skills: [
      "TypeScript",
      "React",
      "Serverless",
      "Node.js",
      "AWS",
      "DynamoDB",
      "Kanban",
      "Scrum",
      "Git",
    ],
  },
  {
    title: "Head of Technology",
    company: "Digia",
    from: new Date("2017-05"),
    to: new Date("2019-08"),
    description: `Enabled a client to shut down approximately fifteen different legacy systems by designing and developing a new digital publishing platform while ensuring that all stakeholders across the organization were satisfied.`,
    skills: [
      "Flow",
      "Node.js",
      "React",
      "Apollo",
      "GraphQL",
      "Laravel",
      "Contentful",
      "Elastic",
      "Git",
      "Domain-Driven Design",
      "Test-Driven Development",
      "Extreme Programming",
    ],
  },
  {
    title: "Chief Technology Officer",
    company: "Nord Software",
    from: new Date("2012-06"),
    to: new Date("2017-04"),
    description: `Grew our team from 10 to 50 developers over a period of five years by improving our development processes, defining best practices, and building development tools, while ensuring the success of all our client projects.`,
    skills: [
      "Flow",
      "JavaScript",
      "Node.js",
      "React",
      "React Native",
      "Serverless",
      "REST",
      "Laravel",
      "Git",
      "Domain-Driven Design",
      "Test-Driven Development",
      "Extreme Programming",
    ],
  },
  // {
  //   title: "Software Engineer",
  //   company: "Nord Software",
  //   from: new Date("2008-11"),
  //   to: new Date("2012-05"),
  //   description: `Launched a online marketplace I managed a large client project using Scrum with up to eight developers and participated in both development and design. I also redesigned and developed the consumer user interface of a large client project.`,
  //   skills: [
  //     "JavaScript",
  //     "AngularJS",
  //     "jQuery",
  //     "Prototype",
  //     "HTML",
  //     "CSS",
  //     "Mercurial",
  //     "Subversion",
  //   ],
  // },
]

export default function Experience() {
  return (
    <Section>
      <Heading2>
        Experience
        <small className="text-sm sm:text-base font-semibold text-gray-600 uppercase ml-2">
          Most recent
        </small>
      </Heading2>
      <ul>
        {data.map((record, i) => (
          <li className={classnames({ "mb-4": i !== data.length - 1 })} key={i}>
            <Heading3>
              {record.title}, {record.company}
            </Heading3>
            <Duration fromDate={record.from} toDate={record.to} showDuration />
            {record.description && <p className="mt-2">{record.description}</p>}
            {record.skills && (
              <ul className="mt-4">
                {record.skills
                  .sort((a, b) => a.localeCompare(b))
                  .map((skill, j) => (
                    <li
                      className={classnames(
                        "text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white bg-purple-800",
                        { "mr-1 mb-1": j !== record.skills!.length - 1 }
                      )}
                    >
                      {skill}
                    </li>
                  ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </Section>
  )
}
